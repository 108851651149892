import { fullDateEn, ordinalDayFullMonthYearFr } from '../constants/date-formats';
import { useEffect, useState } from 'react';
import dayjs from '../utils/dates';
import { LoginAlert } from '../constants/login-alerts';
import { useDateFormat } from './useDateFormat';

export const useAlertLogin = (language: string) => {
  const [alertId, setAlertId] = useState<LoginAlert>();
  const [alertDate, setAlertDate] = useState<string>();
  const dateFormat = useDateFormat(fullDateEn, ordinalDayFullMonthYearFr);

  const envAlertId = process.env.REACT_APP_LOGIN_ALERT;
  const envAlertDate = process.env.REACT_APP_LOGIN_ALERT_TARGET_DATE;

  useEffect(() => {
    switch (envAlertId) {
      case LoginAlert.CURRENTLY_DOWN:
        setAlertId(LoginAlert.CURRENTLY_DOWN);
        break;
      case LoginAlert.PLANNED_MAINTENANCE:
        setAlertId(LoginAlert.PLANNED_MAINTENANCE);
        break;
      case LoginAlert.HOLIDAY:
        const date = dayjs(envAlertDate);
        if (date.isValid() && envAlertDate) {
          setAlertId(LoginAlert.HOLIDAY);
          setAlertDate(date.format(dateFormat));
        }
        break;
      default:
        break;
    }
  }, [envAlertId, envAlertDate, dateFormat, language]);

  return { alertId, alertDate };
};

export default useAlertLogin;
